import logger from "../../../utils/logger";
import ApiClient from "../client";

/**
 * The baseURL in the config is temporary until
 * we move all endpoints over to v6, and we can set
 * the baseURL in the axios instances which is in
 * the /client/client.js file
 */

const Account = {
  namespace: "account",
  basePath: "/v6/account",
  v62BasePath: "/v6.2/member",
  hamptonsBasePath: "/hamptons/eligibility",

  async hashMemberId(token, memberId) {
    if (!memberId) {
      return null;
    }

    const endpoint = `${this.basePath}/external/member/encode`;

    try {
      const resp = await ApiClient._get(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        headers: {
          Authorization: token,
        },
      });

      if (!resp.data) {
        logger.warn(`[hashMemberId v6 api] -> WARNING no data returned`);
        return null;
      }
      return resp.data?.encryptedMemberId;
    } catch (error) {
      logger.error(`[hashMemberId v6 api] -> ERROR ${error}`);
      return error.response.data;
    }
  },

  async getAccountInfo(token) {
    const endpoint = `${this.basePath}/account-information`;

    try {
      const resp = await ApiClient._get(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        headers: {
          Authorization: token,
        },
      });

      if (!resp.data) {
        logger.warn(`[account info v6 api] -> WARNING no data returned`);
        return null;
      }

      const country = resp.data.country;
      const userAvailableStates = ApiClient.config().available_states[country];

      return {
        memberAccountInfo: resp.data,
        userAvailableStates,
      };
    } catch (error) {
      logger.error(`[account info v6 api] -> ERROR ${error}`);
      return error.response.data;
    }
  },

  async updateAccountInformation(data, token) {
    const endpoint = `${this.basePath}/account-information/update`;

    try {
      const resp = await ApiClient._put(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        headers: {
          Authorization: token,
        },
        data,
      });

      if (!resp.data) {
        logger.warn(`[account update v6 api] -> WARNING something went wrong`);
        return null;
      }

      return resp.data;
    } catch (error) {
      logger.error(`[account update v6 api] -> ERROR ${error}`);
      return error && error.response ? error.response.data : null;
    }
  },

  async updateUsername(data, token) {
    const endpoint = `${this.basePath}/username/update`;

    try {
      const resp = await ApiClient._post(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        headers: {
          Authorization: token,
        },
        data,
      });

      if (!resp.data) {
        logger.warn(`[username update v6 api] -> WARNING something went wrong`);
        return null;
      }

      return resp.data;
    } catch (error) {
      logger.error(`[username update v6 api] -> ERROR ${error}`);
      return error && error.response ? error.response.data : null;
    }
  },

  async updatePassword(data, token) {
    const endpoint = `${this.basePath}/password/update`;

    try {
      const resp = await ApiClient._post(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        headers: {
          Authorization: token,
        },
        data,
      });

      if (!resp.data) {
        logger.warn(`[password update v6 api] -> WARNING something went wrong`);
        return null;
      }

      return resp.data;
    } catch (error) {
      logger.error(`[password update v6 api] -> ERROR ${error}`);
      return error && error.response ? error.response.data : null;
    }
  },

  async linkWithFacebook(data, token) {
    const endpoint = `${this.basePath}/linkwithfacebook`;

    try {
      const resp = await ApiClient._post(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        headers: {
          Authorization: token,
        },
        data,
      });

      if (!resp.data) {
        logger.warn(
          `[connect facebook v6 api] -> WARNING something went wrong`
        );
        return null;
      }

      return resp.data;
    } catch (error) {
      logger.error(`[connect facebook v6 api] -> ERROR ${error}`);
      return error && error.response ? error.response.data : null;
    }
  },

  async delinkWithFacebook(token) {
    const endpoint = `${this.basePath}/delinkwithfacebook`;

    try {
      const resp = await ApiClient._delete(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        headers: {
          Authorization: token,
        },
      });

      if (!resp.data) {
        logger.warn(
          `[disconnect facebook v6 api] -> WARNING something went wrong`
        );
        return null;
      }

      return resp.data;
    } catch (error) {
      logger.error(`[disconnect facebook v6 api] -> ERROR ${error}`);
      return error && error.response ? error.response.data : null;
    }
  },

  async getHamptonsElegibilityCheck(token) {
    const endpoint = `${this.v62BasePath}${this.hamptonsBasePath}`;

    try {
      const resp = await ApiClient._get(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      });

      if (!resp.data) {
        logger.warn(`[account info v6 api] -> WARNING no data returned`);
        return null;
      }

      return resp.data;
    } catch (error) {
      logger.error(`[account info v6 api] -> ERROR ${error}`);
      return error.response.data;
    }
  },
};

export default Account;
