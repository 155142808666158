import React from "react";

const Formatting = {
  newLineToBreak: (str) => str.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
  renderMultiLine: (text) => {
    return (
      text &&
      text.split(/\n|\\n/g).map((line, index) => (
        <React.Fragment key={index}>
          {line.trim()} <br />
        </React.Fragment>
      ))
    );
  },
  objectToQueryString: (params) => {
    return Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
  },
  toCamelCase: (str) => {
    return str.replace(/-([a-z])/g, function (m, s) {
      return s.toUpperCase();
    });
  },
  toACDLDataLayerFormat: (dataLayerObj, contentfulObj) => {
    const { pageName, pageSection, pageSubSection, pageType, pageCategory } =
      contentfulObj.fields;

    dataLayerObj.page.name = pageName;
    dataLayerObj.page.section = pageSection;
    dataLayerObj.page.subSection = pageSubSection;
    dataLayerObj.page.type = pageType;

    if (pageCategory) {
      dataLayerObj.page.category = pageCategory.toLowerCase();
    }

    if (pageType) {
      dataLayerObj.page.type = pageType.toLowerCase();
    }
    return dataLayerObj;
  },
  encode: (string) => window.btoa(encodeURIComponent(escape(string))),
  unencode: (encodedString) =>
    unescape(decodeURIComponent(window.atob(encodedString))),
};

export default Formatting;
