import jwt from "jsonwebtoken";
import get from "lodash/get";
import { Cookies } from "react-cookie";

import AccountApiClient from "@/api/client/entities/account";
import MeApiClient from "@/api/client/entities/me";
import { baseEnvironmentConfig } from "@/api/constants";
import { COOKIES } from "@/constants";
import FormattingUtils from "@/utils/helpers/formatting";
import { createItem } from "@/utils/helpers/localStorage";
import { cleanToken } from "@/utils/helpers/token";
import logger from "@/utils/logger";

const receiveNearby = (data) => {
  window.nearby = data;
};

const receiveAuthToken = (token) => {
  window.authToken = token;
};

const getUrlParams = () => {
  window.receiveAuthToken = receiveAuthToken;
  window.receiveNearby = receiveNearby;

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        requestAuthTokenCallback: "window.receiveAuthToken",
      })
    );
  }

  const params = {};
  const query = window.location.search.substring(1);
  const vars = query.split("&");

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }

  window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        getNearby: "window.receiveNearby",
      })
    );

  if (window.authToken) {
    params.token = window.authToken;
  }

  return params;
};

export const getAuthToken = (fromPath, saveReturnUrl = false) => {
  const urlParams = getUrlParams();

  if (!urlParams.token) {
    urlParams.token = new Cookies().get(COOKIES.USER_SESSION_ACCESS_TOKEN.name);

    if (!urlParams.token) {
      if (saveReturnUrl) {
        createItem(
          baseEnvironmentConfig.localStorageItems.loginRedirectUrl,
          fromPath || window.location.pathname
        );
      }

      window.location.href = `${window.location.origin}${baseEnvironmentConfig.session.defaultLoginUrl}`;
    }
  }

  return urlParams.token;
};

export const getAuthTokenWithoutRedirect = () => {
  let bavToken = getUrlParams().token;

  if (!bavToken) {
    bavToken = new Cookies().get(COOKIES.USER_SESSION_ACCESS_TOKEN.name);

    if (!bavToken) {
      bavToken = false;
    }
  }

  return bavToken;
};

export const fetchUserInfo = async (token) => {
  try {
    const authToken = token || getAuthTokenWithoutRedirect();
    const memberInfo = jwt.decode(cleanToken(authToken));

    const memberData = await AccountApiClient.getAccountInfo(authToken);
    const memberProfile = await MeApiClient.getMemberProfile(authToken);
    const memberProfilePic = await MeApiClient.getMemberProfilePic(
      authToken,
      memberInfo["https://equinox.com/properties"].equinoxMemberId
    );
    const memberBillingInfo = await MeApiClient.getBillingInfo(authToken);
    const memberGiftcardDetails =
      await MeApiClient.getGiftcardDetails(authToken);
    const hamptonsElegibilityCheck =
      await AccountApiClient.getHamptonsElegibilityCheck(authToken);

    // this doesn't have the spread because the data comes back different from the original layout
    const memberProfileInfo = { ...memberProfile.memberProfileInfo.profile };
    let hashedMemberId;

    if (authToken && memberProfileInfo?.memberId) {
      hashedMemberId = await AccountApiClient.hashMemberId(
        authToken,
        memberProfileInfo?.memberId
      );
    }

    const userProfile = {
      ...memberData,
      memberProfilePic,
      memberProfileInfo,
      ...memberBillingInfo,
      memberGiftcardDetails,
      token: authToken,
      isLoading: false,
    };

    const userSession = {
      authToken,
      user: {
        firstName: memberProfileInfo.firstName,
        lastName: memberProfileInfo.lastName,
        height: memberProfileInfo.height,
        homeFacilityId: memberProfileInfo.homeFacilityId,
        memberId: memberProfileInfo.memberId,
        membershipStatus: memberProfileInfo.membershipStatus,
        avatar: get(memberProfilePic, "membersSignedURLs.0.signedURL", null),
        publicId: memberProfileInfo.publicId,
        shareId: memberProfileInfo.shareId,
        strikes: memberProfileInfo.strikes,
        trackingId: memberProfileInfo.trackingId,
        weight: memberProfileInfo.weight,
        country: memberData.memberAccountInfo.country.toLowerCase(),
        email: memberData.memberAccountInfo.email,
        hashedMemberId,
        billingEmailAddress: memberBillingInfo.memberBillingInfo.email,
        isProspect: memberData.memberAccountInfo.isProspect,
        facebookId: memberData.memberAccountInfo.facebookId,
        address1: memberData.memberAccountInfo.address1,
        address2: memberData.memberAccountInfo.address2,
        billingAddress1: memberBillingInfo.memberBillingInfo.address1,
        billingAddress2: memberBillingInfo.memberBillingInfo.address2,
        city: memberData.memberAccountInfo.city,
        state: memberData.memberAccountInfo.state,
        zip: memberData.memberAccountInfo.zipCode,
        isConnectedToNetpulse: memberProfile.isConnectedToNetpulse,
        id: memberProfileInfo.userId,
        ptStatus: memberProfileInfo.hasActivePT ? "active" : "inactive",
        accountStatus: memberData.memberAccountInfo.membershipStatus
          ? memberData.memberAccountInfo.membershipStatus.toLowerCase()
          : "",
        hashedEmail: FormattingUtils.encode(memberData.memberAccountInfo.email),
        cof: {
          cardLastFourDigits:
            memberBillingInfo.memberBillingInfo.cardLastFourDigits,
          cardType: memberBillingInfo.memberBillingInfo.cardType,
          expirationDate: memberBillingInfo.memberBillingInfo.expirationDate,
        },
        hasHamptonsAddOnAgreement:
          hamptonsElegibilityCheck.hasHamptonsAddOnAgreement,
        hamptonsAgreementStatusId:
          hamptonsElegibilityCheck.hamptonsAgreementStatusId,
      },
      expired: false,
    };

    return {
      userProfile,
      userSession,
    };
  } catch (error) {
    logger.error("[account v6 api] -> ERROR", error);
  }
};

const UserApi = {
  getAuthToken,
};

export default UserApi;
